import apiService from "./apiService";
const voterService = {
    async sendReport(voter){
        try {
            return (await apiService.post(`m/send-report`, voter, true)).data;
        }
        catch(err) {
            return {success: false, message: "An error occured while processing the request."};
        }
    },

    async searchMaritess(searchModel){
        try {
            return (await apiService.get(`m/search`, searchModel, true)).data;
        }
        catch(err) {
            return [];
        }
    },

};

export default voterService;