<template>
    <div class="row">
        <form @submit.prevent="searchVoter">
            <div class="table-responsive">
                <table class="table align-middle table-striped">
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>BRGY</th>
                        </tr>
                        <tr>
                            <th colspan="2">
                            <div class="input-group">
                                <input placeholder="Enter keyword" type="text" v-model="searchModel.name_voter" class="form-control">
                                <button type="submit"  class="btn btn-sm btn-secondary">
                                    <i class="bi bi-search"></i>
                                </button>
                            </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="cursor: pointer;" @click.stop.prevent="report(v)" v-for="v in voters" :key="v.sypsk_voter">
                            <td>{{ v.name_voter }}</td>
                            <td>{{ v.brgy_voter }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    </div>

    <MediumSizedModal :show="showModal" @close="toggleModal">
        <ReportInfo @on-cancel="toggleModal" :voter="selectedVoter"/>
    </MediumSizedModal>
</template>

<script setup>
import MediumSizedModal from '@/components/modals/MediumSizedModal.vue';
import ReportInfo from '@/components/ReportInfo.vue';
import spinnerService from '@/services/spinnerService';
import voterService from '@/services/voterService';
import { onMounted, ref } from 'vue';


const voters = ref([]);
const searchModel = ref({ name_voter: null })
const selectedVoter = ref({sypsk_voter: 0,side_voter: false});
const showModal = ref(false);

const toggleModal = ()=>{
    showModal.value = !showModal.value;
}

const searchVoter = async () => {
    spinnerService.show();
    voters.value = await voterService.searchMaritess(searchModel.value);
    spinnerService.hide();
}

const report = (voter)=>{
    selectedVoter.value = {...voter}
    showModal.value  =true;
}

onMounted(()=>{
    searchVoter();
})

</script>