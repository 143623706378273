<template>
    <div class="row">
        <h3 class="text-center">{{ voterData.name_voter }}</h3>
        <p class="text-center">{{ voterData.brgy_voter }}</p>

        <div class="d-flex justify-content-center">
            <!-- Toggle Switch for side_voter -->
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="side_voter_toggle" v-model="voterData.side_voter"
                    :class="{ 'active-switch': voterData.side_voter }" />
                <label class="form-check-label" :style="{
                    color: voterData.side_voter ? 'red' : 'blue'
                }" for="side_voter_toggle">
                    {{ voterData.side_voter ? 'RED TEAM' : 'BLUE TEAM' }}
                </label>
            </div>
        </div>
        <form @submit.prevent="handleSubmit">
            <div class="form-group">
                <label>Remarks:</label>
                <textarea required class="form-control" v-model="voterData.remarks_voter"></textarea>
            </div>
            <div class="form-group mt-2">
                <button class="btn btn-danger form-control" type="submit">Submit Report</button>
            </div>
            <div class="form-group mt-2">
                <button @click.prevent="handleCancel" class="btn btn-secondary form-control" type="button">Cancel</button>
            </div>
        </form>
    </div>
</template>

<script>
import confirmationService from "@/services/confirmationService";
import toastService from "@/services/toastService";
import voterService from "@/services/voterService";
import { ref, watch } from "vue";

export default {
    props: {
        voter: {
            type: Object,
            required: true,
            default: () => ({ syspk_voter: 0, side_voter: false, remarks_voter: "ASD" }) // Default to an object with side_voter as false
        }
    },
    emits: ['on-cancel'],
    setup(props,{emit}) {
        const voterData = ref(props.voter);


        const handleSubmit = async ()=>{
            if(voterData.value.remarks_voter.trim() === ""){
                toastService.error("Remarks required!");
                return;
            }
            const confirmed = await confirmationService.show("Are you sure you want to proceed?");
            if(!confirmed)
            return;
            const resp = await voterService.sendReport(voterData.value);
            if(resp.success){
                toastService.success("Report sent successfully and will be reviewed.");
                emit('on-cancel')
            }
            else{
                toastService.error(resp.message);
            }
        }
        const handleCancel = ()=>{
            emit('on-cancel');
        }
        
        
        watch(
            () => props.voter,
            (newVoter) => {
                voterData.value = newVoter;
            },
            { immediate: true }
        );


        return {
            voterData,
            handleSubmit,
            handleCancel
        };
    }
};
</script>

<style scoped>
/* Change the toggle switch color to red when active */
.form-check-input:checked.active-switch {
    background-color: red !important;
    border-color: red !important;
}

/* Optional: Adjust the toggle's slider when active */
.form-check-input:checked.active-switch:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.25);
}

/* Optional: Label style when active */
.form-check-label {
    cursor: pointer;
}
</style>