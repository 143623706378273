<template>
  <SpinnerOverlay />
  <ConfirmDialog />
  <div>
    <nav
      v-if="showAdminHeader"
      class="navbar navbar-expand-lg navbar-danger bg-light fixed-top"
    >
      <div class="container-fluid">
        <div class="d-flex justify-content-left align-items-center">
          <button
            @click="toggleSidebar"
            class="btn btn-sm btn-outline-light me-2 mt-1"
            type="button"
          >
            <i class="bi bi-list text-dark fs-3"></i>
          </button>
          <router-link to="/" class="navbar-brand text-decoration-none" href="#">
            <img
              src="/favicon.ico"
              alt="Logo"
              class="d-inline-block align-text-top"
              width="30"
              height="30"
            />
            <span class="d-none d-lg-inline ms-2">Electrack</span>
          </router-link>
        </div>

        <div class="d-flex align-items-center ms-auto">
          <div class="dropdown">
            <a
              title="Account"
              class="dropdown-toggle username d-flex align-items-center me-2"
              href="#"
              id="userDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-person-circle me-1 fs-2"></i>
              <span class="d-none d-lg-block">
                {{ currentUser ? currentUser.name_user.toUpperCase() : "Guest" }}
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
              <li>
                <h3 class="dropdown-header text-center">
                  {{ currentUser ? currentUser.fullname_user : "" }}
                </h3>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>
              <li>
                <a href="#" @click.prevent="logout" class="dropdown-item">
                  <i class="bi bi-box-arrow-left me-1"></i> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="d-flex b-danger" id="content-container">
      <div
        v-if="showAdminHeader"
        style="z-index: 99"
        :class="[
          'bg-light',
          'border-end',
          'sidebar-wrapper',
          { toggled: isSidebarToggled },
        ]"
        id="sidebar-wrapper"
      >
        <div class="list-group list-group-flush">
          <router-link
            to="/"
            class="list-group-item list-group-item-action bg-light mt-3"
          >
            <i class="bi bi-speedometer me-2"></i> Dashboard
          </router-link>
       
        </div>
      </div>
      <div id="page-content-wrapper" :class="{ toggled: isSidebarToggled }">
        <div class="container-fluid">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount, onMounted, watch } from "vue";
import SpinnerOverlay from "./animations/SpinnerOverlay.vue";
import { getUserRoles } from "@/utils/auth";
import router from "@/router";
import ConfirmDialog from "./animations/ConfirmDialog.vue";
import store from "@/store";
import confirmationService from "@/services/confirmationService";
import { roles } from "@/utils/config";
import signalRService from "@/services/signalRService";

export default {
  components: {
    SpinnerOverlay,
    ConfirmDialog,
  },
  setup() {
    const isSidebarToggled = ref(false);
    const showAdminHeader = ref(store.state.showAdminHeader);
    const adminPrintRoles = computed(() => roles.adminPrint);
    const showLiveScan = ref(false);
    const scanMode = ref("live");

    const onScannerClosed = () => {
      showLiveScan.value = false;
    };

    const toggleLiveScan = (mode) => {
      scanMode.value = mode;
      showLiveScan.value = !showLiveScan.value;
    };

    const hasRequiredRole = (requiredRoles) => {
      return requiredRoles.some((role) => getUserRoles().includes(role));
    };

    const toggleSidebar = () => {
      isSidebarToggled.value = !isSidebarToggled.value;
    };

    const currentUser = computed(() => store.getters["auth/currentUser"] ?? null);

    const logout = async () => {
      const confirmed = await confirmationService.show(
        "Are you sure you want to logout?"
      );
      if (!confirmed) return;
      store.dispatch("showAdminHeader", false);
      await store.dispatch("auth/logout");
      router.push("/login");
      //window.location.replace("/login");
    };
    watch(
      () => store.state.showAdminHeader,
      () => {
        showAdminHeader.value = store.state.showAdminHeader;
      }
    );

    // Manage header visibility based on route meta field
    const updateHeaderVisibility = () => {
      const currentRoute = router.currentRoute.value;
      showAdminHeader.value = currentRoute.meta.showHeader !== false;
    };

    onMounted(() => {
      updateHeaderVisibility();
      // showAdminHeader.value = store.state.showAdminHeader;
    });

    onBeforeUnmount(() => {
      signalRService.connection
        .stop()
        .then(() => {
          console.log("SignalR connection stopped");
        })
        .catch((err) => {
          console.error("Error stopping SignalR connection:", err);
        });
    });

    return {
      isSidebarToggled,
      toggleSidebar,
      showAdminHeader,
      currentUser,
      logout,
      hasRequiredRole,
      adminPrintRoles,
      showLiveScan,
      toggleLiveScan,
      onScannerClosed,
      scanMode,
    };
  },
};
</script>

<style scoped>
#content-container {
  margin-top: 55px;
  height: calc(100vh - 55px);
}

a.username {
  text-decoration: none;
  color: rgb(158, 29, 29);
}

.sidebar-wrapper {
  width: 250px;
  transition: all 0.3s ease;
  height: calc(100vh - 55px);
}

#page-content-wrapper {
  width: 100%;
  padding: 20px;
  transition: all 0.3s ease;
}

.sidebar-wrapper.toggled {
  transform: translateX(-100%);
}

#page-content-wrapper.toggled {
  margin-left: -250px;
}

@media (max-width: 767px) {
  .sidebar-wrapper {
    width: 250px;
    position: fixed;
    transform: translateX(-100%);
  }

  .sidebar-wrapper.toggled {
    transform: translateX(0);
  }

  #page-content-wrapper.toggled {
    margin-left: 0;
  }

  #page-content-wrapper {
    margin-left: 0;
  }
}
</style>
